// this function will update the existing crumbtrail if the referrer is the resource center results page
import * as DOMPurify from 'dompurify'

if (document.referrer.indexOf('/resource-center') > 0) {
  const menu = document.querySelector('ameriprise-menu')
  if (menu) {
    const crumbtrail = menu.shadowRoot.querySelectorAll(
      '.amp-mainBreadcrumb-link'
    )
    if (crumbtrail && crumbtrail.length === 3) {
      if (crumbtrail[0].text === 'Financial Goals & Priorities') {
        crumbtrail[1].text = 'Your resources'
        crumbtrail[1].href = DOMPurify.sanitize(document.referrer)
        crumbtrail[1].onclick = 'window.history.back();'
      }
    }
  }
}
